<template>
  <v-container
    :style="{
      maxWidth: '1000px',
    }"
    class="pt-0"
  >
    <h3 class="text-center flex-grow-1 mb-5">
      Мы рады приветствовать Вас в Assessment management system!
    </h3>
    <p class="text-justify">
      <b>Assessment management system</b> - это система для проведения онлайн
      центров оценки (ассессмент-центров) и центров развития. Во время оценки
      Вам будет предложено выполнить несколько заданий, приближенных к реальным,
      в современной бизнес-симуляции.
    </p>
    <br />
    <p v-if="session.sessionId">
      Ваша оценка состоится
      <b
        >{{ sessionTimestamps.date }} в {{ sessionTimestamps.startTime }} и
        продлится до {{ sessionTimestamps.endTime }}</b
      >
    </p>
    <p v-else>Оценка не назначена</p>
    <v-row class="mt-8">
      <v-col cols="3">
        <div class="assessment-step">
          <div
            class="assessment-step_icon"
            :style="{
              backgroundImage: `url(${icons.preparing})`,
            }"
          ></div>
          <h3 class="assessment-step_title">
            1. Изучите материалы для подготовки и проверьте доступы в
            бизнес-симуляцию
          </h3>
          <p class="assessment-step_desc">
            Вам потребуется от 30 до 60 минут, чтобы ознакомиться с материалами
          </p>
          <v-btn
            text
            class="assessment-step_action"
            @click="$emit('tab-selected', 'Preparing')"
            >Изучить материалы</v-btn
          >
        </div>
      </v-col>
      <v-col cols="3">
        <div class="assessment-step">
          <div
            class="assessment-step_icon"
            :style="{
              backgroundImage: `url(${icons.assessment})`,
              height: '100px',
              margin: '10px 0',
            }"
          ></div>
          <h3 class="assessment-step_title">
            2. Пройдите оценку, выполняя задания в бизнес-симуляции
          </h3>
          <p class="assessment-step_desc">
            {{
              seconds > 0
                ? `Доступ к бизнес-симуляции откроется через ${timer}`
                : session.simulationAvailable
                ? "Оценка доступна"
                : "Оценка недоступна"
            }}
          </p>
          <v-btn
            text
            :class="[
              'assessment-step_action',
              {
                '--disabled assessment-step_action--disabled':
                  !session.simulationAvailable,
              },
            ]"
            @click="getSimulation"
            >Перейти к оценке</v-btn
          >
        </div>
      </v-col>
      <v-col cols="3">
        <div class="assessment-step">
          <div
            class="assessment-step_icon"
            :style="{
              backgroundImage: `url(${icons.questionnaire})`,
            }"
          ></div>
          <h3 class="assessment-step_title">
            3. Заполните анкету обратной связи
          </h3>
          <p class="assessment-step_desc">
            Доступ к анкете обратной связи откроется после завершения оценки
          </p>
          <v-btn
            @click="$emit('tab-selected', 'Questionnaire')"
            text
            :class="[
              'assessment-step_action',
              {
                '--disabled assessment-step_action--disabled':
                  !isQuestionnaireReady,
              },
            ]"
            >Заполнить анкету</v-btn
          >
        </div>
      </v-col>
      <v-col cols="3">
        <div class="assessment-step">
          <div
            class="assessment-step_icon"
            :style="{
              backgroundImage: `url(${icons.feedback})`,
            }"
          ></div>
          <h3 class="assessment-step_title">
            4. Получите обратную связь по итогам оценки
          </h3>
          <p class="assessment-step_desc">
            Время и формат обратной связи Вы можете уточнить у администратора
          </p>
          <v-btn
            v-if="reportPublished"
            @click="moveToReport"
            text
            class="assessment-step_action"
            >Посмотреть отчет</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="10">
        <p class="hint mt-4">
          При появлении вопросов напишите нам на почту
          <span class="subtitle">
            <a href="mailto:ams.support@mozlab.ru">ams.support@mozlab.ru</a>
          </span>
        </p>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <preloader v-if="loading" />
  </v-container>
</template>

<script>
import { ref, computed, watch, onMounted, inject } from "@vue/composition-api";

export default {
  setup(_, { root, emit }) {
    const store = root.$store;

    const session = computed(
      () => store.getters.getParticipantAssessmentSession
    );

    const icons = {
      preparing: require("@/assets/img/assessment-steps/preparing.png"),
      assessment: require("@/assets/img/assessment-steps/assessment.png"),
      questionnaire: require("@/assets/img/assessment-steps/questionnaire.png"),
      feedback: require("@/assets/img/assessment-steps/feedback.png"),
    };

    const seconds = ref(0);
    let interval;

    const startTimer = () => {
      seconds.value = Math.floor(
        (Date.parse(session.value.assessmentTimestamp) - Date.now()) / 1000
      );
      clearInterval(interval);
      interval = setInterval(async () => {
        if (seconds.value > 0) {
          seconds.value--;
        } else {
          clearInterval(interval);
          seconds.value = 0;
          await store.dispatch("fetchParticipantAssessmentSession");
        }
      }, 1000);
    };

    onMounted(() => {
      startTimer();
      localStorage.setItem("lastTab", "Main");
    });

    const reportPublished = computed(() => session.value.isReportPublished);
    const isQuestionnaireReady = computed(
      () => session.value.isQuestionnaireAvailable
    );

    watch(
      () => session.value.assessmentTimestamp,
      () => {
        startTimer();
      }
    );

    const timer = computed(() => {
      let d = Math.floor(seconds.value / 60 / 60 / 24);
      let h = Math.floor(seconds.value / 60 / 60) - d * 24;
      let m = Math.floor(seconds.value / 60) - h * 60 - d * 24 * 60;
      let s = seconds.value % 60;

      if (d) return `${d}дн. ${h}ч. ${m}м. ${s}с.`;

      if (h) return `${h}ч. ${m}м. ${s}с.`;

      if (m) return `${m}м. ${s}с.`;

      if (s) return `${s}с.`;
    });

    const getSimulation = async () => {
      let error = await store.dispatch("fetchSimulation");

      if (error) {
        root.$toast.error("Оценочная сессия недоступна");
      } else {
        emit("tab-selected", "Simulation");
      }
    };

    const sessionTimestamps = computed(() => {
      const date =
        new Date(session.value.assessmentTimestamp).toLocaleString("ru-RU", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        }) || "...";
      const startTime =
        new Date(session.value.assessmentTimestamp).toLocaleString("ru-RU", {
          hour: "2-digit",
          minute: "2-digit",
        }) || "...";
      const endTime =
        new Date(session.value.assessmentEndTimestamp).toLocaleString("ru-RU", {
          hour: "2-digit",
          minute: "2-digit",
        }) || "...";

      return {
        date,
        startTime,
        endTime,
      };
    });

    const loading = inject("loading");
    const moveToReport = async () => {
      loading.value = true;
      await store.dispatch("fetchCurrentReport", {
        sessionId: session.value.sessionId,
        role: store.getters.getCurrentRole,
      });
      loading.value = false;
      emit("tab-selected", "Report");
    };

    return {
      session,
      icons,
      seconds,
      timer,
      getSimulation,
      sessionTimestamps,
      reportPublished,
      isQuestionnaireReady,
      loading,
      moveToReport,
    };
  },
  components: {},
};
</script>

<style lang="scss" scoped>
#app {
  .card-link {
    min-height: 80px;
    &_title {
      color: $blackColor;
    }
  }
}
</style>
<style lang="scss">
#app {
  .assessment-step {
    display: flex;
    flex-direction: column;
    height: 340px;
    &_icon {
      height: 120px;
      background-size: contain;
      background-position: 50%;
    }
    &_title {
      padding-top: 15px;
    }
    &_desc {
      flex-grow: 1;
    }
    &_action {
      border: 1px solid #b5b5b5;
      &:hover {
        border-color: #4c5ca7;
      }
      &--disabled {
        background-color: #b5b5b5;
      }
    }
  }
}
</style>
