var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",style:({
    maxWidth: '1000px',
  })},[_c('h3',{staticClass:"text-center flex-grow-1 mb-5"},[_vm._v(" Мы рады приветствовать Вас в Assessment management system! ")]),_c('p',{staticClass:"text-justify"},[_c('b',[_vm._v("Assessment management system")]),_vm._v(" - это система для проведения онлайн центров оценки (ассессмент-центров) и центров развития. Во время оценки Вам будет предложено выполнить несколько заданий, приближенных к реальным, в современной бизнес-симуляции. ")]),_c('br'),(_vm.session.sessionId)?_c('p',[_vm._v(" Ваша оценка состоится "),_c('b',[_vm._v(_vm._s(_vm.sessionTimestamps.date)+" в "+_vm._s(_vm.sessionTimestamps.startTime)+" и продлится до "+_vm._s(_vm.sessionTimestamps.endTime))])]):_c('p',[_vm._v("Оценка не назначена")]),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"assessment-step"},[_c('div',{staticClass:"assessment-step_icon",style:({
            backgroundImage: ("url(" + (_vm.icons.preparing) + ")"),
          })}),_c('h3',{staticClass:"assessment-step_title"},[_vm._v(" 1. Изучите материалы для подготовки и проверьте доступы в бизнес-симуляцию ")]),_c('p',{staticClass:"assessment-step_desc"},[_vm._v(" Вам потребуется от 30 до 60 минут, чтобы ознакомиться с материалами ")]),_c('v-btn',{staticClass:"assessment-step_action",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('tab-selected', 'Preparing')}}},[_vm._v("Изучить материалы")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"assessment-step"},[_c('div',{staticClass:"assessment-step_icon",style:({
            backgroundImage: ("url(" + (_vm.icons.assessment) + ")"),
            height: '100px',
            margin: '10px 0',
          })}),_c('h3',{staticClass:"assessment-step_title"},[_vm._v(" 2. Пройдите оценку, выполняя задания в бизнес-симуляции ")]),_c('p',{staticClass:"assessment-step_desc"},[_vm._v(" "+_vm._s(_vm.seconds > 0 ? ("Доступ к бизнес-симуляции откроется через " + _vm.timer) : _vm.session.simulationAvailable ? "Оценка доступна" : "Оценка недоступна")+" ")]),_c('v-btn',{class:[
            'assessment-step_action',
            {
              '--disabled assessment-step_action--disabled':
                !_vm.session.simulationAvailable,
            } ],attrs:{"text":""},on:{"click":_vm.getSimulation}},[_vm._v("Перейти к оценке")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"assessment-step"},[_c('div',{staticClass:"assessment-step_icon",style:({
            backgroundImage: ("url(" + (_vm.icons.questionnaire) + ")"),
          })}),_c('h3',{staticClass:"assessment-step_title"},[_vm._v(" 3. Заполните анкету обратной связи ")]),_c('p',{staticClass:"assessment-step_desc"},[_vm._v(" Доступ к анкете обратной связи откроется после завершения оценки ")]),_c('v-btn',{class:[
            'assessment-step_action',
            {
              '--disabled assessment-step_action--disabled':
                !_vm.isQuestionnaireReady,
            } ],attrs:{"text":""},on:{"click":function($event){return _vm.$emit('tab-selected', 'Questionnaire')}}},[_vm._v("Заполнить анкету")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"assessment-step"},[_c('div',{staticClass:"assessment-step_icon",style:({
            backgroundImage: ("url(" + (_vm.icons.feedback) + ")"),
          })}),_c('h3',{staticClass:"assessment-step_title"},[_vm._v(" 4. Получите обратную связь по итогам оценки ")]),_c('p',{staticClass:"assessment-step_desc"},[_vm._v(" Время и формат обратной связи Вы можете уточнить у администратора ")]),(_vm.reportPublished)?_c('v-btn',{staticClass:"assessment-step_action",attrs:{"text":""},on:{"click":_vm.moveToReport}},[_vm._v("Посмотреть отчет")]):_vm._e()],1)])],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"hint mt-4"},[_vm._v(" При появлении вопросов напишите нам на почту "),_c('span',{staticClass:"subtitle"},[_c('a',{attrs:{"href":"mailto:ams.support@mozlab.ru"}},[_vm._v("ams.support@mozlab.ru")])])])]),_c('v-spacer')],1),(_vm.loading)?_c('preloader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }